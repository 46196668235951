import React from 'react';
import {LegacyStack, Button} from '@shopify/polaris';
import {updateRule} from '../../../../../helpers/updateRule';
import { updateCustomization } from '../../../../../helpers/updateCustomization';
import update from 'immutability-helper';

const OfferStatusButton = (props) => {
  const {token, shop, firebase, state, setState} = props;

  const toggleEnabled = (rule) => {
    if("custom content" === rule.type || "custom field" === rule.type || "address blocker" === rule.type){
      updateCustomization({
        id: rule.id,
        enabled: !rule.enabled
      }, shop, firebase);
    }else{
    updateRule({
        id: rule.id,
        enabled: !rule.enabled
      },
      token, shop, firebase);
    }
  };

  const clickHandler = (e) => {
    e.stopPropagation();
    toggleEnabled(state);

    setState(update(state, { enabled: { $set: !state.enabled }}))
  }

  return (
    (<LegacyStack>
      <Button
        onClick={(e) => clickHandler(e)}
        variant={!state.enabled ? "primary" : "secondary"}
        fullWidth
      >
        {state.enabled ? 'Deactivate' : 'Activate'}
      </Button>
    </LegacyStack>)
  );
};

export default OfferStatusButton;